<template>
  <div>
    <v-select solo label="Rembug" class="mb-4" hide-details :items="kelompok_angsuran" item-text="cm_name"
      item-value="cm_code" v-model="list.cm_code" @change="
        $emit(
          'refreshKelompokAngsuran',
          list.cm_code,
          list.branch_id,
          list.transaction_date
        )
      " />

    <v-menu ref="menu" v-model="dateShow" :close-on-content-click="false" :return-value.sync="list.transaction_date"
      transition="scale-transition" offset-y min-width="auto" class="white">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field solo v-model="list.transaction_date" label="Tanggal" readonly v-bind="attrs" v-on="on" />
      </template>
      <v-date-picker v-model="list.transaction_date" no-title scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateShow = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="
            $refs.menu.save(list.transaction_date);
            $emit(
              'refreshKelompokAngsuran',
              list.cm_code,
              list.branch_id,
              list.transaction_date
            );
          ">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>

    <v-container class="pa-0" v-if="list.kelompok_angsuran && list.kelompok_angsuran.length > 0">
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <div class="d-flex justify-space-between">
          <span><b>Total Setoran</b></span>
          <h5>Rp {{ thousand(total) }}</h5>
        </div>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3"
        v-for="(agt, agtIndex) in list.kelompok_angsuran" :key="agtIndex">
        <v-container class="d-flex justify-space-between pa-0 flex-column">
          <h5 class="text-h6 font-weight-bold d-inline-flex justify-space-between align-center">
            {{ agt.nama_anggota }}
            <small class="text-caption grey--text ms-3">{{
              agt.no_anggota
              }}</small>
          </h5>
          <div class="d-flex block flex-row justify-space-between align-items-center">
            <span><b>Kelompok {{ agt.kode_kelompok }} </b></span>
            <h5 class="text-end">Rp {{ thousand(agt.setoran) }}</h5>
          </div>
        </v-container>
        <v-divider class="my-2" />
        <v-container class="pa-0 d-flex justify-space-between">
          <v-row class="justify-end">
            <v-col cols="4">
              <router-link
                :to="`/angsuran/anggota/${agt.cm_code}/${list.branch_id}/${agt.kode_kelompok}/${list.transaction_date}`">
                <v-btn small block class="purple lighten-1 white--text rounded-lg" type="submit">
                  Transaksi
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <v-container class="d-flex justify-space-between pa-0 flex-column">
          <div class="d-flex block flex-row justify-space-between align-items-center">
            <span><b>Total Infaq </b></span>
            <h5 class="text-end">Rp {{ thousand(infaq) }}</h5>
          </div>
        </v-container>
        <v-divider class="my-2" />
        <v-container class="pa-0 d-flex justify-space-between">
          <v-row class="justify-end">
            <v-col cols="4">
              <router-link :to="`/angsuran/infaq/${list.cm_code}/${list.branch_id}/${list.transaction_date}/${infaq}`">
                <v-btn small block class="purple lighten-1 white--text rounded-lg" type="submit">
                  Transaksi
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <v-container class="d-flex justify-space-between pa-0 flex-column">
          <router-link :to="`/angsuran/kumpulan/`">
            <v-btn small block class="purple darken-1 white--text rounded-lg" type="submit">
              KEMBALI
            </v-btn>
          </router-link>
        </v-container>
      </v-card>
    </v-container>
    <v-container class="pa-0" v-else>
      <v-card class="white elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <v-container class="d-flex justify-space-between pa-0">
          <div class="d-flex flex-column">
            <h5 class="text-h5 font-weight-bold">
              {{ list.loading ? "Memproses data..." : "Tidak ada anggota" }}
            </h5>
          </div>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  props: ["list", "target", "total", "infaq"],
  components: {
    Toast,
  },
  data() {
    return {
      alert: {
        show: false,
        msg: "",
      },
      dateShow: false,
      date: null,
      kelompok_angsuran: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    date: {
      handler(val) {
        this.$emit(
          "refreshKelompokAngsuran",
          this.list.kelompok_angsuran,
          this.date
        );
      },
      deep: true,
    },
  },
  methods: {
    ...helper,
    async getRembug() {
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", '99');
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            this.kelompok_angsuran = req.data.data;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
